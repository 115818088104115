export function groupBy(key, array) {
    return Array.from(array).reduce((obj, element, index, array) => {
        let group =
            element[
                typeof key === 'function' ? key(element, index, array) : key
            ];
        if (!obj[group]) obj[group] = [element];
        else obj[group].push(element);
        return obj;
    }, {});
}

export function deepAssign(target = {}, ...sources) {
    for (let source of sources) {
        for (let key of Object.keys(source)) {
            target[key] =
                source[key]?.constructor === Object
                    ? deepAssign(target[key], source[key])
                    : source[key];
        }
    }
    return target;
}

export function isNumeric(object) {
    return parseFloat(object) === Number(object);
}

export function divideOrZero(dividend, divisor) {
    return divisor ? dividend / divisor : 0;
}

export function formatValue(
    value,
    options = {},
    locales = navigator.languages,
) {
    let formatter = new Intl.NumberFormat(locales, options);
    //if (options.style === 'currency') {
    //value = Math.round(value);
    //}
    //if (!options.notation) {
    /* eslint-disable no-irregular-whitespace */
    //return formatter.formatToParts(value)
    //.map(({type, value}) =>
    //type === 'group'    ? ' ' :  // narrow no-break space (\x202f / &#8239;)
    //type === 'decimal'  ? '' :  // empty string
    //type === 'fraction' ? '' :  // empty string
    //value
    //).join('');
    //}
    return formatter.format(value);
}

export function formatDate(value, options = {}, locales = navigator.languages) {
    let date = new Date(value);
    let formatter = new Intl.DateTimeFormat(locales, options);
    return date.toJSON() ? formatter.format(date) : value;
}

export function formatDateByGranularity(
    value,
    granularity,
    locales = navigator.languages,
) {
    switch (granularity) {
        case 'year':
            return formatDate(value, { year: 'numeric' }, locales);
        case 'month':
            return formatDate(value, { month: 'short' }, locales);
        case 'week':
            return value?.toString().replace(/.*(W\d{1,2}).*/, '$1');
        default:
            return formatDate(
                value,
                { day: 'numeric', month: 'numeric' },
                locales,
            );
    }
}

export function localISODate(value = new Date()) {
    let date = new Date(value);
    return (
        date.toJSON() &&
        [
            date.getFullYear(),
            (date.getMonth() + 1).toString().padStart(2, '0'),
            date.getDate().toString().padStart(2, '0'),
        ].join('-')
    );
}

export function localISOTime(value = new Date()) {
    let date = new Date(value);
    return date.toJSON() && date.toTimeString().slice(0, 8);
}

export function localISOString(value = new Date()) {
    let date = new Date(value);
    return (
        date.toJSON() &&
        localISODate(date) +
            'T' +
            date.toTimeString().slice(0, 17).replace(' GMT', '')
    );
}

export function queryParams() {
    return Object.fromEntries(new URLSearchParams(location.search));
}

export function debounce(func, wait = 250) {
    clearTimeout(func._timeout);
    func._timeout = setTimeout(func, wait);
}

export function compare(a, b) {
    return a > b ? 1 : a < b ? -1 : 0; // cf. the 'spaceship' operator ( <=> ) in other languages
}

export function unique(array) {
    return Array.from(new Set(array));
}

export async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function fileSizeWithin(file, maxSizeInMB) {
    return file.size <= maxSizeInMB * 1024 * 1024;
}

export function hasSlot(slots, name) {
    const slot = slots[name];

    if (!slot) {
        return false;
    }

    const slotLength = slot().length > 0;

    if (!slotLength) {
        return false;
    }

    const children = slot()[0].children;

    if (Array.isArray(children)) {
        const nestedChildren = children[0].children;

        return nestedChildren !== '';
    }

    return children !== '';
}

export function getPairPriceForOfferSize(pairs, priceRangeMinPairs, offerSize) {
    if (pairs >= priceRangeMinPairs.bulk && offerSize.bulk_price) {
        return offerSize.bulk_price;
    }

    if (
        pairs >= priceRangeMinPairs.intermediate &&
        offerSize.intermediate_price
    ) {
        return offerSize.intermediate_price;
    }

    return offerSize.base_price;
}

export function pagePropsIncludes(pageProps = {}, props = []) {
    return Object.keys(pageProps).some((key) => props.includes(key));
}

function formatCurrency(value, currency) {
    return formatValue(value, {
        style: 'currency',
        currency,
    });
}

export function formatMessage(orderMessage) {
    let message = orderMessage.message;
    const variables = orderMessage.variables;

    message = message.replace(/\n/g, '<br />');

    if (!variables) {
        return message;
    }

    for (const variable of variables) {
        const type = variable.type;
        const name = variable.name;

        if (type === 'currency') {
            const formattedVariable = formatCurrency(
                variable.value,
                variable.currency_code,
            );

            message = message.replace(`{{${name}}}`, formattedVariable);
        }
    }

    return message;
}

export function isMinBreakpoint(
    currentBreakpoint,
    minBreakpoint,
    breakpoints = ['sm', 'md', 'lg', 'xl', '2xl'],
) {
    return (
        breakpoints.indexOf(currentBreakpoint) >=
            breakpoints.indexOf(minBreakpoint) || false
    );
}

export function getFractionDigits(value) {
    return (value.toString().split('.')[1] || '').length;
}
